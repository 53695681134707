class PhoneWithPrefix extends HTMLElement {
  connectedCallback () {
    if (this.hasAttribute('data-initialized')) {
      return;
    }

    this.prefixOptions = this.querySelector('phone-prefix-options')
    this.prefixSelect = this.querySelector('phone-prefix-select')
    this.numberInput = this.querySelector('input[type=tel]')
    this.validationTimeout = null
    this.expectedType = this.dataset.type
    this.expectedCountries = this.dataset.countries

    this.declaraListeners()

    this.setAttribute('data-initialized', 'true')
  }

  declaraListeners () {
    if (this.listenersAttached) {
      return;
    }

    this.prefixSelect.addEventListener('click', ev => {
      this.desplegarOpciones()
    })

    this.prefixOptions.querySelectorAll('phone-prefix-option').forEach(option => {
      option.addEventListener('click', ev => {
        const textoPrefijo = option.getAttribute('value')
        this.prefixSelect.textContent = textoPrefijo
        this.prefixSelect.setAttribute('value', textoPrefijo)
        this.prefixSelect.title = option.dataset.nombre
        this.replegarOpciones()
        this.chequeaValidez()
      })
    })

    this.numberInput.addEventListener('input', () => {
      this.chequeaValidez()
    })

    this.form.addEventListener('submit', () => {
      if (this.numberInput.value.length > 0) {
        this.inputTelefonoCompleto.value = this.telefonoCompleto
      } else if (!this.numberInput.required) {
        this.inputTelefonoCompleto.value = ''
      }
    }, true)

    this.pedirTokenSMS = (function (event) {
      event.preventDefault()
      if (!this.form.checkValidity()) {
        this.form.reportValidity()
      } else {
        var self = this
        const form = self.form
        const boton = self.form.commit
        const numero = self.inputTelefonoCompleto
        const url = '/telefono/enviar-sms?telefono=' + encodeURIComponent(self.telefonoCompleto)
        fetch(url, { credentials: 'same-origin' })
          .then(function (response) {
            return response.text()
          })
          .then(function (html) {
            const modal = new Dialog(html, { closingx: true })
            const formSMS = modal.querySelector('form')
            const btnCorregir = modal.querySelector('#corregir-numero')
            const cerrarModalSMS = function () {
              modal.destroy()
              form.dispatchEvent(new CustomEvent('dialog-sms-closed')) //usar este evento
            }

            form.dispatchEvent(new CustomEvent('dialog-sms-opened'))

            formSMS.addEventListener('ajax:success', function () {
              cerrarModalSMS()
              boton.removeEventListener('click', self.pedirTokenSMS, false)
              boton.click()
            })
            formSMS.addEventListener('ajax:error', function (event) {
              cerrarModalSMS()
              alert(event.detail[0])
            })
            btnCorregir.onclick = function () {
              cerrarModalSMS()
              numero.focus()
            }
          })
      }
    }).bind(this)

    if (this.enviarSMS) {
      this.form.commit.addEventListener('click', this.pedirTokenSMS, false)
    }

    this.listenersAttached = true
  }

  chequeaValidez () {
    clearTimeout(this.validationTimeout)

    // inválido hasta que se demuestre lo contrario
    this.dispatchEvent(new CustomEvent('phone-is-invalid'))

    this.validationTimeout = setTimeout(() => {
      if (!this.numberInput.required && this.numberInput.value.length === 0) {
        this.hacerValido()
      } else if (this.numberInput.value.length < 5) {
        // solo existen numeros validos a partir de 5 digitos
        // https://bit.ly/2HkTrGM
        this.hacerInvalido('Faltan dígitos')
      } else {
        const url = new URL('/telefono/es-valido', location.origin)
        url.searchParams.append('telefono', this.telefonoCompleto)
        if (this.expectedType) {
          url.searchParams.append('tipo', this.expectedType)
        }
        if (this.expectedCountries) {
          url.searchParams.append('paises', this.expectedCountries)
        }

        fetch(url, { credentials: 'same-origin' })
          .then(response => {
            response.ok ? this.hacerValido() : response.text().then(texto => this.hacerInvalido(texto))
          })
      }
    }, 400)
  }

  hacerValido () {
    this.numberInput.setCustomValidity('')
    this.inputTelefonoCompleto.value = this.telefonoCompleto
    this.dispatchEvent(new CustomEvent('phone-is-valid'))
  }

  hacerInvalido (errorMsg) {
    this.numberInput.setCustomValidity(errorMsg)
    this.inputTelefonoCompleto.value = ''
    this.dispatchEvent(new CustomEvent('phone-is-invalid'))
  }

  desplegarOpciones () {
    this.prefixOptions.setAttribute('open', '')
  }

  replegarOpciones () {
    this.prefixOptions.hasAttribute('open') && this.prefixOptions.removeAttribute('open')
  }

  get telefonoCompleto () {
    return this.prefixSelect.getAttribute('value') + this.numberInput.value
  }

  get form () {
    return this.numberInput.form
  }

  get enviarSMS () {
    return this.inputTelefonoCompleto.getAttribute('sms') === 'true'
  }

  get inputTelefonoCompleto () {
    return this.querySelector('input[bind]')
  }
}

export default PhoneWithPrefix
